<template>
  <div class="entrypoint-modal-api mb-4">
    <div class="flex overflow-clip rounded-lg border dark:border-gray-700">
      <div
        v-for="(step, i) in configurationSteps"
        :key="step.key"
        :class="getStyles(i, 'bg')"
        class="flex h-10 w-1/3 items-center justify-center gap-2 px-4 transition-colors duration-300"
      >
        <span :class="getStyles(i, 'icon')" class="text-base leading-none">{{
          TAGS[i]
        }}</span>
        <span :class="getStyles(i, 'text')" class="step-text text-xs">
          {{ step.title }}
        </span>
      </div>
    </div>
    <div class="mb-2 mt-4 flex items-center gap-2">
      <p class="m-0">{{ configurationSteps[currentStep].helpText }}</p>
      <fluent-icon
        v-if="currentStep > 0"
        v-tooltip.top="configurationSteps[currentStep].tooltip"
        icon="info"
        :size="12"
      />
    </div>
    <div
      v-if="currentStep === 0"
      class="rounded-lg bg-gray-100 p-4 pt-2 dark:bg-gray-900"
    >
      <codemirror-input
        v-model="localData"
        @format-changed="onAPIFormatChanged"
      />
    </div>
    <vue-json-pretty
      v-if="currentStep === 1"
      :data="parsedJSON"
      :deep="5"
      :node-selectable="isNodeSelectable"
      :selected-value.sync="selectedKeys"
      :style="{ paddingLeft: '36px' }"
      class="h-96 overflow-y-auto rounded-lg border p-4 ps-6 dark:border-gray-700"
      selectable-type="multiple"
      show-icon
      show-line-number
      show-select-controller
      @selected-change="onSelectedChange"
    />
    <div
      v-if="currentStep === 2"
      class="border-secondary-200 h-96 space-y-2 overflow-y-auto rounded-lg bg-gray-100 p-2 dark:bg-gray-900"
    >
      <div
        v-if="mapperInfo"
        class="callout mb-3 flex items-center gap-2 text-sm"
        :class="mapperInfo.type === 'warning' ? 'warning' : 'primary'"
      >
        <fluent-icon :icon="mapperInfo.type" type="outline" />
        {{ mapperInfo.message }}
      </div>
      <entrypoint-edit-merge-field
        v-for="field in localMergeFields"
        :key="field.key"
        :merge-field="field"
        :attribute-keys="usedAttributes"
        @update:merge-field="onMergeFieldUpdate"
        @delete:merge-field="onMergeFieldDelete"
      />
    </div>
    <delete-modal
      :show="isDeleteModalShown"
      :title="
        $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.API.DELETE_ATTRIBUTE_TITLE')
      "
      :message="
        $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.API.DELETE_ATTRIBUTE_MESSAGE')
      "
      :reject-text="$t('CHATLYN_GENERAL.BUTTON.CANCEL')"
      :confirm-text="$t('CHATLYN_GENERAL.BUTTON.DELETE')"
      :on-close="toggleDeleteModalShown"
      :on-confirm="removeMergeField"
    />
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import EntrypointEditMergeField from './EntrypointEditMergeField';
import CodemirrorInput from 'shared/components/CodemirrorInput';
import DeleteModal from 'dashboard/components/widgets/modal/DeleteModal';
import { isJSONValid } from 'dashboard/helper/commons';
import { TAGS } from 'dashboard/constants/common';
import {
  isXMLValid,
  parseXML,
  getMultilineXML,
} from 'dashboard/helper/xmlHelpers';
import {
  getAllPossibleKeys,
  getDataType,
  getValueFromJSON,
  parseJsonInObject,
  getMergeFieldsKeys,
  isDefinedValue,
} from '../utils/mergeFieldsUtils';

export default {
  name: 'EntrypointEditApi',
  components: {
    VueJsonPretty,
    DeleteModal,
    EntrypointEditMergeField,
    CodemirrorInput,
  },
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    hasAutomations: {
      type: Boolean,
      default: false,
    },
    sampleData: {
      type: String,
      default: '',
    },
    mergeFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      TAGS,
      isDeleteModalShown: false,
      mergeFieldToDelete: null,
      APIformat: 'JSON',
    };
  },
  computed: {
    configurationSteps() {
      return [
        {
          key: `step-1`,
          title: this.$t(
            `CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.API.STEP_1.TITLE`
          ),
          helpText: this.$t(
            `CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.API.STEP_1.HELP_TEXT`,
            { format: this.APIformat }
          ),
        },
        {
          key: `step-2`,
          title: this.$t(
            `CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.API.STEP_2.TITLE`
          ),
          helpText: this.$t(
            `CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.API.STEP_2.HELP_TEXT`
          ),
          tooltip: this.$t(
            `CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.API.STEP_2.TOOLTIP`
          ),
        },
        {
          key: `step-3`,
          title: this.$t(
            `CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.API.STEP_3.TITLE`
          ),
          helpText: this.$t(
            `CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.API.STEP_3.HELP_TEXT`
          ),
          tooltip: this.$t(
            `CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.API.STEP_3.TOOLTIP`
          ),
        },
      ];
    },
    mappedPhoneOrEmail() {
      return (
        this.hasMergeFieldByType('email') || this.hasMergeFieldByType('phone')
      );
    },
    hasPhoneForContactAttrs() {
      return (
        !this.hasMergeFieldByType('contact') ||
        this.hasMergeFieldByType('phone')
      );
    },
    isValidMergeFields() {
      return this.hasPhoneForContactAttrs && this.isMergeFieldsFilled;
    },
    isMergeFieldsFilled() {
      return this.localMergeFields.every(
        ({
          attributeLabel,
          dateFormat,
          advNumPars,
          dateTimezoneShift,
          ...rest
        }) => {
          const filledProperties = Object.values(rest).every(
            (value) => value || value.toString()
          );
          const filledLabel =
            rest.attributeType !== 'automation' || !!attributeLabel;
          return filledProperties && filledLabel;
        }
      );
    },
    isValidData() {
      return this.APIformat === 'JSON'
        ? isJSONValid(this.localData)
        : isXMLValid(this.localData);
    },
    isValid() {
      if (this.currentStep === 1) {
        return !!this.localMergeFields.length;
      }
      if (this.currentStep === 2) {
        return !!this.localMergeFields.length && this.isValidMergeFields;
      }
      return this.isValidData;
    },
    localData: {
      get() {
        if (this.sampleData && isJSONValid(this.sampleData)) {
          return JSON.stringify(JSON.parse(this.sampleData), null, 2);
        }
        if (this.sampleData && isXMLValid(this.sampleData)) {
          return getMultilineXML(this.sampleData);
        }
        return this.sampleData || '';
      },
      set(val) {
        this.$emit('update:JSON', val);
      },
    },
    localMergeFields: {
      get() {
        return this.mergeFields;
      },
      set(val) {
        this.$emit('update:merge-fields', val);
      },
    },
    mapperInfo() {
      if (!this.hasPhoneForContactAttrs) {
        return {
          type: 'warning',
          message: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.WARNING_REQUIRED'
          ),
        };
      }
      if (!this.localMergeFields.length) {
        return {
          type: 'warning',
          message: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.WARNING_EMPTY'
          ),
        };
      }
      if (this.mappedPhoneOrEmail) {
        return {
          type: 'info',
          message: this.$t(
            'CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.INFO_PHONE_EMAIL'
          ),
        };
      }
      return null;
    },
    parsedJSON() {
      if (isJSONValid(this.localData)) {
        return parseJsonInObject(JSON.parse(this.localData));
      }
      if (this.localData && isXMLValid(this.localData)) {
        const parsedXML = parseXML(this.localData);
        return parseJsonInObject(parsedXML);
      }
      return {};
    },
    selectedKeys() {
      const keys = this.localMergeFields.map(({ key }) => key);
      const dataSetKeys = keys.filter((key) => !!key.includes('[i]'));
      let keysToSelect = keys.filter((key) => !key.includes('[i]'));
      if (dataSetKeys.length) {
        dataSetKeys.forEach((key) => {
          const allDataSetKeys = getAllPossibleKeys(this.parsedJSON, key);
          keysToSelect = keysToSelect.concat(allDataSetKeys);
        });
      }

      return keysToSelect.map((key) => {
        const rootPrefix = key[0] === '[' ? 'root' : 'root.';
        return rootPrefix + key;
      });
    },
    stepStyles() {
      return {
        active: {
          bg: 'bg-woot-50 dark:bg-woot-800',
          icon: 'text-woot-500',
          text: 'text-gray-800 dark:text-white',
        },
        next: {
          bg: 'bg-white dark:bg-gray-700',
          icon: 'text-gray-200 dark:text-gray-400',
          text: 'text-gray-500 dark:text-gray-400',
        },
        previous: {
          bg: 'bg-woot-50 dark:bg-woot-800',
          icon: 'text-woot-200 dark:text-woot-500 dark:opacity-50',
          text: 'text-woot-400 dark:text-woot-500 dark:opacity-50',
        },
      };
    },
    usedAttributes() {
      return this.localMergeFields.map(({ attributeKey }) => attributeKey);
    },
  },
  watch: {
    currentStep(_, prevStep) {
      if (prevStep === 0 && this.localMergeFields.length) {
        this.localMergeFields = this.updateSelected();
      }
    },
    isValid(val) {
      this.$emit('validate-api', val);
    },
  },
  methods: {
    hasMergeFieldByType(type) {
      return this.localMergeFields.some(
        ({ attributeType }) => attributeType === type
      );
    },
    generateNewField(key) {
      const value = getValueFromJSON(this.parsedJSON, key);
      return {
        key,
        exampleValue: value.toString(),
        dataType: getDataType(value),
        attributeType: '',
        attributeKey: '',
        attributeLabel: '',
        required: false,
      };
    },
    mapMergeField(JSONpath) {
      const key = JSONpath.replace(/root\.{0,1}/, '');
      const existingMergeField = this.localMergeFields.find(
        (field) => field.key === key
      );
      return existingMergeField || this.generateNewField(key);
    },
    onAPIFormatChanged(format) {
      this.APIformat = format;
    },
    updateSelected() {
      return this.localMergeFields.reduce((acc, field) => {
        const value = getValueFromJSON(this.parsedJSON, field.key);
        if (value) {
          acc.push({
            ...field,
            ...(value !== field.exampleValue && {
              exampleValue: value,
              dataType: getDataType(value),
            }),
          });
        }
        return acc;
      }, []);
    },

    getStyles(index, styleProp) {
      if (index === this.currentStep) {
        return this.stepStyles.active[styleProp];
      }
      return index > this.currentStep
        ? this.stepStyles.next[styleProp]
        : this.stepStyles.previous[styleProp];
    },
    isNodeSelectable(node) {
      return node.type === 'content' && isDefinedValue(node.content);
    },
    onMergeFieldDelete(deletedKey) {
      this.mergeFieldToDelete = deletedKey;
      if (this.hasAutomations) {
        this.toggleDeleteModalShown();
      } else {
        this.removeMergeField();
      }
    },
    onMergeFieldUpdate(updatedMergeField) {
      this.localMergeFields = this.localMergeFields.map((field) =>
        field.key === updatedMergeField.key ? { ...updatedMergeField } : field
      );
    },
    onSelectedChange(curr, prev) {
      const keys = getMergeFieldsKeys(curr, prev);
      this.localMergeFields = keys.map((key) => this.mapMergeField(key));
      this.$emit('step-validation', !!keys.length);
    },
    removeMergeField() {
      this.localMergeFields = this.localMergeFields.filter(
        (field) => field.key !== this.mergeFieldToDelete
      );
      if (this.isDeleteModalShown) {
        this.isDeleteModalShown = false;
      }
    },
    toggleDeleteModalShown() {
      this.isDeleteModalShown = !this.isDeleteModalShown;
    },
  },
};
</script>

<style lang="scss">
.vjs-tree-node {
  .vjs-key {
    @apply text-gray-800 dark:text-gray-100;
  }
  .vjs-value {
    &.vjs-value-string {
      @apply text-green-700 dark:text-green-500;
    }
    &.vjs-value-number {
      @apply text-blue-600 dark:text-blue-500;
    }
    &.vjs-value-boolean {
      @apply text-purple-600 dark:text-purple-500;
    }
    &.vjs-value-null {
      @apply text-red-700 dark:text-red-500;
    }
  }
}
</style>

<style lang="scss" scoped>
.entrypoint-modal-api ::v-deep {
  textarea {
    height: 340px;
    resize: none;
  }
  .vjs-tree-node {
    @apply pl-8 font-mono text-base;
    &:hover,
    &.is-highlight {
      @apply bg-woot-500/10;
    }
    .vjs-carets {
      top: 3px;
      &:hover {
        @apply text-woot-500;
      }
    }
    .vjs-node-index {
      @apply text-gray-500;
    }
    .vjs-check-controller {
      @apply leading-none transition-all duration-300;
      top: 2px;
      left: 2px;
      .vjs-check-controller-inner {
        @apply border-gray-500 bg-transparent dark:border-gray-600;
      }

      &.is-checked .vjs-check-controller-inner {
        @apply border-woot-500 bg-woot-500;
        border-radius: 3px;
        &::after {
          border-width: 1px;
          left: 5px;
          width: 3px;
        }
      }
    }
  }
}
</style>
