import { PARTNERS } from './constants/partners';
import store from './store';

export const FEATURE_FLAGS = {
  AGENT_BOTS: 'agent_bots',
  AGENT_MANAGEMENT: 'agent_management',
  AUTO_RESOLVE_CONVERSATIONS: 'auto_resolve_conversations',
  AUTOMATIONS: 'automations',
  CAMPAIGNS: 'campaigns',
  CANNED_RESPONSES: 'canned_responses',
  CRM: 'crm',
  CUSTOM_ATTRIBUTES: 'custom_attributes',
  INBOX_MANAGEMENT: 'inbox_management',
  INTEGRATIONS: 'integrations',
  LABELS: 'labels',
  MESSAGE_TEMPLATES: 'message_templates',
  SCHEDULED_MESSAGE_TEMPLATES: 'scheduled_message_templates',
  MACROS: 'macros',
  HELP_CENTER: 'help_center',
  REPORTS: 'reports',
  TEAM_MANAGEMENT: 'team_management',
  VOICE_RECORDER: 'voice_recorder',
  AUDIT_LOGS: 'audit_logs',
  INSERT_ARTICLE_IN_REPLY: 'insert_article_in_reply',
  INBOX_VIEW: 'inbox_view',
  SLA: 'sla',
  RESPONSE_BOT: 'response_bot',
  CHANNEL_EMAIL: 'channel_email',
  CHANNEL_FACEBOOK: 'channel_facebook',
  CHANNEL_TWITTER: 'channel_twitter',
  CHANNEL_WEBSITE: 'channel_website',
  CUSTOM_REPLY_DOMAIN: 'custom_reply_domain',
  CUSTOM_REPLY_EMAIL: 'custom_reply_email',
  DISABLE_BRANDING: 'disable_branding',
  EMAIL_CONTINUITY_ON_API_CHANNEL: 'email_continuity_on_api_channel',
  INBOUND_EMAILS: 'inbound_emails',
  IP_LOOKUP: 'ip_lookup',
  LINEAR: 'linear_integration',
  SMS: 'sms',
  CONTACT_SEARCH: 'contact-search',
  CONTACT_IMPORTER: 'contact-importer',
  DYNAMIC_LISTS: 'dynamic-lists',
};

const features = [
  {
    name: FEATURE_FLAGS.DYNAMIC_LISTS,
    disabledPartners: [PARTNERS.BRANDNAMIC],
  },
  {
    name: FEATURE_FLAGS.CONTACT_SEARCH,
    disabledPartners: [PARTNERS.BRANDNAMIC],
  },
  {
    name: FEATURE_FLAGS.CONTACT_IMPORTER,
    disabledPartners: [PARTNERS.BRANDNAMIC],
  },
  {
    name: FEATURE_FLAGS.MACROS,
    disabledPartners: [PARTNERS.BRANDNAMIC],
  },
];

const isFeatureEnabledForPartner = (disabledPartners = []) => {
  const partner = store.getters['accounts/getPartner'];

  return disabledPartners.length === 0 || !disabledPartners.includes(partner);
};

export const isFeatureEnabled = (featureName) => {
  const currentFeature = features.find(({ name }) => name === featureName);

  return currentFeature
    ? isFeatureEnabledForPartner(currentFeature.disabledPartners)
    : false;
};
