<template>
  <div
    v-if="hasSecondaryMenu"
    class="flex h-full w-48 flex-col overflow-y-auto overflow-x-hidden border-r px-2 pb-8 text-sm surface-panel brd-highlight rtl:border-l rtl:border-r-0"
  >
    <account-context @toggle-accounts="toggleAccountModal" />
    <transition-group
      name="menu-list"
      tag="ul"
      class="mb-0 ml-0 list-none pt-2"
    >
      <secondary-nav-item
        v-for="menuItem in accessibleMenuItems"
        :key="menuItem.toState"
        :menu-item="menuItem"
      />

      <secondary-nav-item
        v-for="menuItem in additionalSecondaryMenuItems[menuConfig.parentNav]"
        :key="menuItem.key"
        :menu-item="menuItem"
        @add-label="showAddLabelPopup"
        @add-list="showAddListPopup"
        @add-entrypoint="showAddEntrypointPopup"
        @query-changed="(query) => handleQueryChanged(menuItem, query)"
      />
    </transition-group>
  </div>
</template>
<script>
import { frontendURL } from '../../../helper/URLHelper';
import SecondaryNavItem from './SecondaryNavItem.vue';
import AccountContext from './AccountContext.vue';
import { mapGetters } from 'vuex';
import { FEATURE_FLAGS, isFeatureEnabled } from '../../../featureFlags';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';
import {
  MESSAGE_TEMPLATES_CHANNEL_TYPE,
  MESSAGE_TEMPLATES_CHANNEL_TYPES_MENU_ITEM,
  MESSAGE_TEMPLATES_CHANNEL_TYPE_BY_INBOX_TYPE,
} from '../../../routes/dashboard/messageTemplates/constants';
import { mapFoldersAndItemsToListStructure } from '../../ui/DragDropList/constants';
import { ARCHIVE_TYPE } from 'shared/constants/archiveType';
import { hasPermissions } from '../../../helper/permissionsHelper';
import { routesWithPermissions } from '../../../routes';

export default {
  components: {
    AccountContext,
    SecondaryNavItem,
  },
  props: {
    accountId: {
      type: Number,
      default: 0,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    inboxes: {
      type: Array,
      default: () => [],
    },
    teams: {
      type: Array,
      default: () => [],
    },
    customViews: {
      type: Array,
      default: () => [],
    },
    menuConfig: {
      type: Object,
      default: () => {},
    },
    currentUser: {
      type: Object,
      default: () => {},
    },
    isOnChatwootCloud: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      contactLists: 'contactLists/getLists',
      allFolders: 'folders/getAllFolders',
      allEntrypoint: 'entrypoint/getAllEntrypoint',
      contactListsUiFlags: 'contactLists/getUiFlags',
      foldersUiFLags: 'folders/getFoldersUiFlags',
    }),

    showMacros() {
      return isFeatureEnabled(FEATURE_FLAGS.MACROS);
    },

    isContactListSectionLoading() {
      return (
        this.contactListsUiFlags.isLoading || this.foldersUiFLags.isFetching
      );

      // return (
      //   Object.values(this.contactListsUiFlags).some((value) => !!value) ||
      //   Object.values(this.foldersUiFLags).some((value) => !!value)
      // );
    },

    hasSecondaryMenu() {
      return (
        this.menuConfig.hasSecondaryMenu ||
        (this.menuConfig.menuItems && this.menuConfig.menuItems.length)
      );
    },

    contactCustomViews() {
      return this.customViews.filter((view) => view.filter_type === 'contact');
    },

    accessibleMenuItems() {
      const menuItemsFilteredByPermissions = this.menuConfig.menuItems.filter(
        (menuItem) => {
          const { permissions: userPermissions = [] } = this.currentUser;
          return hasPermissions(
            routesWithPermissions[menuItem.toStateName],
            userPermissions
          );
        }
      );
      return menuItemsFilteredByPermissions.filter((item) => {
        if (item.showOnlyOnCloud) {
          return this.isOnChatwootCloud;
        }

        if (item.featureFlag === FEATURE_FLAGS.MACROS && !this.showMacros) {
          return false;
        }
        return true;
      });
    },

    contactListMenuOptions() {
      const itemOptions = {
        add: {
          label: this.$t('FOLDERS.MENU_ITEMS.ADD_LIST'),
          value: 'add_list',
          icon: 'text-bullet-list-add',
          action: this.showAddListPopup,
          dataTestid: 'list-add-option',
        },
        edit: {
          label: this.$t('FOLDERS.MENU_ITEMS.SETTINGS'),
          value: 'settings',
          icon: 'settings',
          action: this.showEditListPopup,
          dataTestid: 'list-settings-option',
        },
        archive: {
          label: this.$t('FOLDERS.MENU_ITEMS.ARCHIVE'),
          value: 'archive',
          icon: 'archive',
          action: (id) => this.showArchivePopup(ARCHIVE_TYPE.CONTACT_LIST, id),
          dataTestid: 'list-archive-option',
        },
        unarchive: {
          label: this.$t('FOLDERS.MENU_ITEMS.UNARCHIVE'),
          value: 'unarchive',
          icon: 'archive-arrow-back',
          action: (id) =>
            this.showArchivePopup(ARCHIVE_TYPE.CONTACT_LIST, id, true),
          dataTestid: 'list-unarchive-option',
        },
        delete: {
          label: this.$t('FOLDERS.MENU_ITEMS.DELETE'),
          value: 'delete',
          icon: 'delete',
          action: this.showDeleteListPopup,
          dataTestid: 'list-delete-option',
        },
      };

      const foldersOptions = {
        add: {
          label: this.$t('FOLDERS.MENU_ITEMS.ADD_FOLDER'),
          value: 'add_folder',
          icon: 'folder-add',
          action: this.showAddFolderPopup,
          dataTestid: 'folder-add-option',
        },
        edit: {
          label: this.$t('FOLDERS.MENU_ITEMS.EDIT'),
          value: 'rename',
          icon: 'edit',
          action: this.showEditFolderPopup,
          dataTestid: 'folder-edit-option',
        },
        archive: {
          label: this.$t('FOLDERS.MENU_ITEMS.ARCHIVE'),
          value: 'archive',
          icon: 'archive',
          action: (id) => this.showArchivePopup(ARCHIVE_TYPE.FOLDER, id),
          dataTestid: 'folder-archive-option',
        },
        unarchive: {
          label: this.$t('FOLDERS.MENU_ITEMS.UNARCHIVE'),
          value: 'unarchive',
          icon: 'archive-arrow-back',
          action: (id) => this.showArchivePopup(ARCHIVE_TYPE.FOLDER, id, true),
          dataTestid: 'folder-unarchive-option',
        },
        delete: {
          label: this.$t('FOLDERS.MENU_ITEMS.DELETE'),
          value: 'delete',
          icon: 'delete',
          action: this.showDeleteFolderPopup,
          dataTestid: 'folder-delete-option',
        },
      };

      return {
        main: [foldersOptions.add, itemOptions.add],
        folder: [
          itemOptions.add,
          {
            value: 'separator',
          },
          foldersOptions.archive,
          foldersOptions.edit,
          foldersOptions.delete,
        ],
        item: [itemOptions.archive, itemOptions.edit, itemOptions.delete],
        archived: {
          folder: [foldersOptions.unarchive],
          item: [itemOptions.unarchive],
        },
      };
    },

    contactListsSection() {
      return {
        icon: '',
        label: 'CHATLYN_CONTACT_LISTS.SIDEBAR.LABEL',
        hasSubMenu: true,
        key: 'list',
        newLink: true,
        newLinkTag: 'CHATLYN_CONTACT_LISTS.SIDEBAR.NEW_LIST',
        toState: frontendURL(
          `accounts/${this.accountId}/settings/contact-lists`
        ),
        toStateName: 'lists_list',
        showModalForNewItem: true,
        modalName: 'AddList',
        hasSearch: true,
        hasFolders: true,
        hasArchived: true, // Only if hasFolders is true
        searchInputPlaceholder: this.$t(
          'CONTACTS_PAGE.LIST.SEARCH_INPUT_PLACEHOLDER'
        ),
        emptyStateText: this.$t('CONTACTS_PAGE.LIST.NO_RESULT'),
        queryChanged: this.fetchContactList,
        menuOptions: this.contactListMenuOptions,
        updateFolders: this.updateFolders,
        updateItems: this.updateLists,
        isLoading: this.isContactListSectionLoading,
        children: mapFoldersAndItemsToListStructure(
          this.allFolders,
          this.contactLists.map((child) => ({
            ...child,
            routeParams: {
              // eslint-disable-next-line no-underscore-dangle
              id: child._id,
              label: child.name,
              count: child.count,
              truncateLabel: true,
              toState: frontendURL(
                // eslint-disable-next-line no-underscore-dangle
                `accounts/${this.accountId}/lists/${child._id}/contacts`
              ),
            },
          }))
        ),
      };
    },

    entrypointSection() {
      return {
        dataTestid: 'create-entrypoint',
        icon: '',
        label: 'CHATLYN_AUTOMATIONS.ENTRY_POINTS',
        hasSubMenu: true,
        key: 'entrypoint',
        newLink: true,
        newLinkTag: 'CHATLYN_AUTOMATIONS.NEW_ENTRYPOINT',
        toState: frontendURL(`accounts/${this.accountId}/automations`),
        toStateName: 'automations_dashboard',
        showModalForNewItem: true,
        modalName: 'AddEntrypoint',
        children:
          this.allEntrypoint.length > 0
            ? this.allEntrypoint.map((entrypoint) => ({
                // eslint-disable-next-line no-underscore-dangle
                id: entrypoint._id,
                label: entrypoint.name,
                truncateLabel: true,
                toState: frontendURL(
                  // eslint-disable-next-line no-underscore-dangle
                  `accounts/${this.accountId}/automations/${entrypoint._id}`
                ),
              }))
            : [],
      };
    },

    inboxSection() {
      return {
        icon: 'folder',
        label: 'SIDEBAR.INBOXES',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.INBOX_MANAGEMENT),
        newLinkTag: 'SIDEBAR.NEW_INBOX',
        key: 'inbox',
        toState: frontendURL(`accounts/${this.accountId}/settings/inboxes/new`),
        toStateName: 'settings_inbox_new',
        newLinkRouteName: 'settings_inbox_new',
        children: this.inboxes
          .map((inbox) => ({
            id: inbox.id,
            label: inbox.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/inbox/${inbox.id}`
            ),
            type:
              inbox?.additional_attributes?.apiInboxType === 'WhatsApp'
                ? 'Channel::Whatsapp'
                : inbox.channel_type,
            apiInboxType: inbox?.additional_attributes?.apiInboxType || '',
            phoneNumber:
              inbox.phone_number || `+${inbox?.additional_attributes?.number}`,
            reauthorizationRequired: inbox.reauthorization_required,
          }))
          .sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
          ),
      };
    },
    labelSection() {
      return {
        icon: 'number-symbol',
        label: 'SIDEBAR.LABELS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'SIDEBAR.NEW_LABEL',
        key: 'label',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        dataTestid: 'sidebar-new-label-button',
        children: this.labels.map((label) => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/label/${label.title}`
          ),
        })),
      };
    },
    contactLabelSection() {
      return {
        icon: 'number-symbol',
        label: 'SIDEBAR.LABELS',
        hasSubMenu: true,
        key: 'label',
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'SIDEBAR.NEW_LABEL',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        children: this.labels.map((label) => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/labels/${label.title}/contacts`
          ),
        })),
      };
    },
    teamSection() {
      return {
        icon: 'people-team',
        label: 'SIDEBAR.TEAMS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'SIDEBAR.NEW_TEAM',
        key: 'team',
        toState: frontendURL(`accounts/${this.accountId}/settings/teams/new`),
        toStateName: 'settings_teams_new',
        newLinkRouteName: 'settings_teams_new',
        children: this.teams.map((team) => ({
          id: team.id,
          label: team.name,
          truncateLabel: true,
          toState: frontendURL(`accounts/${this.accountId}/team/${team.id}`),
        })),
      };
    },
    foldersSection() {
      return {
        icon: 'folder',
        label: 'SIDEBAR.CUSTOM_VIEWS_FOLDER',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter((view) => view.filter_type === 'conversation')
          .map((view) => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/custom_view/${view.id}`
            ),
          })),
      };
    },
    contactSegmentsSection() {
      return {
        icon: 'folder',
        label: 'CUSTOM_VIEWS_SEGMENTS',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter((view) => view.filter_type === 'contact')
          .map((view) => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/contacts/custom_view/${view.id}`
            ),
          })),
      };
    },

    messageTemplatesChannelTypes() {
      const userChannelTypes = [MESSAGE_TEMPLATES_CHANNEL_TYPE.UNIVERSAL];

      [INBOX_TYPES.SMS, INBOX_TYPES.WHATSAPP, INBOX_TYPES.TELEGRAM].forEach(
        (inboxType) => {
          if (this.inboxes.some((inbox) => inbox.channel_type === inboxType)) {
            userChannelTypes.push(
              MESSAGE_TEMPLATES_CHANNEL_TYPE_BY_INBOX_TYPE[inboxType]
            );
          }
        }
      );

      return userChannelTypes.map((userChannelType) => {
        const channelTypeMenuItem = Object.assign(
          {},
          MESSAGE_TEMPLATES_CHANNEL_TYPES_MENU_ITEM[userChannelType]
        );

        channelTypeMenuItem.title = this.$i18n.t(channelTypeMenuItem.title);
        channelTypeMenuItem.value = userChannelType;

        return channelTypeMenuItem;
      });
    },

    additionalSecondaryMenuItems() {
      let conversationMenuItems = [
        this.inboxSection,
        this.getLabelSection(
          'SIDEBAR.LABELS',
          `accounts/${this.accountId}/label/{{LABEL_TITLE}}`
        ),
      ];

      let contactMenuItems = [
        this.contactListsSection,
        this.getLabelSection(
          'SIDEBAR.LABELS',
          `accounts/${this.accountId}/labels/{{LABEL_TITLE}}/contacts`
        ),
      ];

      let automationsMenuItems = [this.entrypointSection];

      let messageTemplatesMenuItems = [
        /* todo: matthias reactivate once ready
        this.getMessageTemplatesChannelTypeSection(
          'CHANNEL_TYPE',
          `accounts/${this.accountId}/label/{{CHANNEL_TYPE}}/message-templates`
        ), */
      ];

      if (this.teams.length) {
        conversationMenuItems = [this.teamSection, ...conversationMenuItems];
      }
      if (this.customViews.length) {
        conversationMenuItems = [this.foldersSection, ...conversationMenuItems];
      }
      if (this.contactCustomViews.length) {
        contactMenuItems = [this.contactSegmentsSection, ...contactMenuItems];
      }

      return {
        conversations: conversationMenuItems,
        contacts: contactMenuItems,
        messageTemplates: messageTemplatesMenuItems,
        automations: automationsMenuItems,
      };
    },
  },
  methods: {
    showAddLabelPopup() {
      this.$emit('add-label');
    },

    showAddListPopup(folderId = '') {
      this.$emit('add-list', folderId);
    },

    showAddEntrypointPopup() {
      this.$emit('add-entrypoint');
    },

    showAddFolderPopup() {
      this.$emit('add-folder');
    },

    showEditListPopup(listId) {
      this.$emit('edit-list', listId);
    },

    showDeleteListPopup(listId) {
      this.$emit('delete-list', listId);
    },

    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },

    showNewLink(featureFlag) {
      return this.isFeatureEnabledonAccount(this.accountId, featureFlag);
    },

    getLabelSection(sectionLabel, route /* including {{LABEL_TITLE}} */) {
      return {
        icon: 'number-symbol',
        label: sectionLabel,
        hasSubMenu: true,
        key: 'label',
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'SIDEBAR.NEW_LABEL',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        children: this.labels.map((label) => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(route.replace('{{LABEL_TITLE}}', label.title)),
        })),
      };
    },

    getMessageTemplatesChannelTypeSection(
      sectionLabel,
      route /* including {{CHANNEL_TYPE}} */
    ) {
      return {
        icon: '',
        label: sectionLabel,
        hasSubMenu: true,
        key: 'channel_type',
        showModalForNewItem: false,
        children: this.messageTemplatesChannelTypes.map((channelType) => ({
          id: channelType.id,
          label: channelType.title,
          icon: channelType.icon,
          truncateLabel: true,
          toState: frontendURL(
            route.replace('{{CHANNEL_TYPE}}', channelType.value)
          ),
        })),
      };
    },

    async fetchFolders() {
      await this.$store.dispatch('folders/getAllFolders', {
        clientId: this.currentAccountId,
      });
    },

    async fetchContactList(query) {
      await this.$store.dispatch('contactLists/getLists', {
        accountId: this.currentAccountId,
        requestParams: {
          search: query,
        },
      });
    },

    async handleQueryChanged(menuItem, query) {
      if (typeof menuItem.queryChanged !== 'function') {
        return;
      }

      await menuItem.queryChanged(query);
    },

    async updateLists(lists) {
      await this.$store.dispatch('contactLists/updateLists', {
        lists,
      });
    },

    async updateFolders(folders) {
      await this.$store.dispatch('folders/updateFolders', {
        clientId: this.currentAccountId,
        folders,
      });
    },

    showArchivePopup(archiveType, id, unarchive = false) {
      this.$emit('archive-item', archiveType, id, unarchive);
    },

    showEditFolderPopup(id) {
      this.$emit('edit-folder', id);
    },

    showDeleteFolderPopup(id) {
      this.$emit('delete-folder', id);
    },
  },
};
</script>
